<template>
  <div>
    <div
      class="custom-page-head"
      :class="{
        transparent: theme === 'transparent',
        light: theme === 'light',
        relative: initTheme === 'light',
      }"
    >
      <div class="page-head-container">
        <div class="custom-head">
          <div class="head-container">
            <div class="head-item">
              <div class="head-logo"></div>
            </div>
            <div class="head-menus">
              <div class="head-menu-item">
                <router-link to="/" class="menu-item-text">首页</router-link>
                <!-- <div class="menu-item-text">首页</div> -->
              </div>
              <div class="head-menu-item">
                <!-- <div class="menu-item-text">产品服务</div> -->
                <div class="menu-item-text">解决方案</div>
                <div class="menu-item-block">
                  <div class="menu-item-product">
                    <div class="product-block">
                      <div class="product-block-left">
                        <!-- <div class="category-title">产品服务</div> -->
                        <div class="category-title">解决方案</div>
                        <div
                          v-for="(n, i) in products"
                          v-bind:key="i"
                          @click="pageToSolution(n)"
                          @mouseover="setProductCarousel(i)"
                          class="category-item"
                        >
                          {{ n.name }}
                          <i class="category-arrow el-icon-right"></i>
                        </div>
                      </div>
                      <div class="product-block-right">
                        <el-carousel
                          ref="productCarousel"
                          arrow="never"
                          height="492px"
                          :autoplay="false"
                          indicator-position="none"
                        >
                          <el-carousel-item
                            v-for="(n, i) in products"
                            v-bind:key="`carousel_${i}`"
                          >
                            <div class="product-contents">
                              <div class="product-info">
                                <div class="product-info-title">
                                  {{ n.title }}
                                </div>
                                <div class="product-info-text">
                                  {{ n.desc }}
                                </div>
                                <div
                                  @click="pageToSolution(n)"
                                  class="product-info-btn"
                                >
                                  <!-- 立即免费体验  -->
                                  立即获取解决方案
                                  <i class="btn-icon el-icon-right"></i>
                                </div>
                              </div>
                              <el-image
                                class="product-banner"
                                :src="n.img"
                              ></el-image>
                            </div>
                          </el-carousel-item>
                        </el-carousel>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- <el-popover placement="bottom-start" trigger="hover">
                  <div class="menu-item-text" slot="reference">产品服务</div>
                  <div class="product-list">
                    <div
                      @click="openProduct(n)"
                      v-for="(n, i) in products"
                      v-bind:key="i"
                      class="product-item"
                    >
                      {{ n.name }}<i class="el-icon-arrow-right"></i>
                    </div>
                  </div>
                </el-popover> -->
              </div>
              <!-- <div class="head-menu-item">
                <div class="menu-item-text">解决方案</div>
              </div> -->
              <!-- <div class="head-menu-item">
                <div class="menu-item-text">应用市场</div>
              </div> -->
              <div class="head-menu-item">
                <router-link to="/custom" class="menu-item-text"
                  >开发定制</router-link
                >
                <!-- <div class="menu-item-text">开发定制</div> -->
              </div>
              <div class="head-menu-item">
                <div class="menu-item-text">生态合作</div>
              </div>
              <div class="head-menu-item">
                <router-link to="/about" class="menu-item-text"
                  >关于椰豆</router-link
                >
                <!-- <div class="menu-item-text">关于椰豆</div> -->
              </div>
            </div>
            <div class="head-item">
              <!-- <div class="head-item-btn">退出</div> -->
              <div @click="pageToHome" v-if="loginUser" class="head-dashboard">
                管理中心
              </div>
              <router-link v-else to="/loginplat" class="head-item-btn"
                >登录</router-link
              >
              <!-- <div class="head-item-btn">免费注册</div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getLoginInfo } from "@/api/shop";
export default {
  data() {
    return {
      theme: "dark",
      windowWidth: 1920,
      products: [
        {
          sign: "scrm",
          name: "企微助手",
          // name: "企微SCRM私域运营",
          title: "数字化运营管理平台",
          desc: "更快速的连接，更高效的增长，以企业的客户运营为中心，整合线上和线下，打通公域及私域，打造「获客·运营·转化·管理」一体化数字营销闭环系统，助力企业私域精细化运营，帮助企业用好企业微信，赋能员工精细化运营私域，实现360°私域客户营销和管理，智能分配客户，实时追踪不同渠道客户添加效果",
          img: "https://image.ydleague.com/benefit/web_icons/icon_website_about_banner_01.png",
        },
        {
          sign: "edu",
          name: "在线教育",
          title: "知识产品与用户服务的私域运营工具",
          desc: "更快速的连接，更高效的增长，以企业的客户运营为中心，整合线上和线下，打通公域及私域，打造「获客·运营·转化·管理」一体化数字营销闭环系统，助力企业私域精细化运营，帮助企业用好企业微信，赋能员工精细化运营私域，实现360°私域客户营销和管理，智能分配客户，实时追踪不同渠道客户添加效果",
          img: "https://image.ydleague.com/benefit/web_icons/icon_website_about_banner_01.png",
        },
        {
          sign: "mall",
          name: "微商城",
          title: "全渠道商城，社交裂变营销",
          desc: "更快速的连接，更高效的增长，以企业的客户运营为中心，整合线上和线下，打通公域及私域，打造「获客·运营·转化·管理」一体化数字营销闭环系统，助力企业私域精细化运营，帮助企业用好企业微信，赋能员工精细化运营私域，实现360°私域客户营销和管理，智能分配客户，实时追踪不同渠道客户添加效果",
          img: "https://image.ydleague.com/benefit/web_icons/icon_website_about_banner_01.png",
        },
        {
          sign: "store",
          name: "门店零售",
          title: "全时全域经营，管理数字化升级",
          desc: "更快速的连接，更高效的增长，以企业的客户运营为中心，整合线上和线下，打通公域及私域，打造「获客·运营·转化·管理」一体化数字营销闭环系统，助力企业私域精细化运营，帮助企业用好企业微信，赋能员工精细化运营私域，实现360°私域客户营销和管理，智能分配客户，实时追踪不同渠道客户添加效果",
          img: "https://image.ydleague.com/benefit/web_icons/icon_website_about_banner_01.png",
        },
        {
          sign: "beauty",
          name: "智慧美业",
          title: "美业广告投放、获客、运营连锁一体化经营",
          desc: "更快速的连接，更高效的增长，以企业的客户运营为中心，整合线上和线下，打通公域及私域，打造「获客·运营·转化·管理」一体化数字营销闭环系统，助力企业私域精细化运营，帮助企业用好企业微信，赋能员工精细化运营私域，实现360°私域客户营销和管理，智能分配客户，实时追踪不同渠道客户添加效果",
          img: "https://image.ydleague.com/benefit/web_icons/icon_website_about_banner_01.png",
        },
        {
          sign: "hotel",
          name: "智慧酒店",
          title: "打造多业态场景式消费体验的智慧酒店",
          desc: "更快速的连接，更高效的增长，以企业的客户运营为中心，整合线上和线下，打通公域及私域，打造「获客·运营·转化·管理」一体化数字营销闭环系统，助力企业私域精细化运营，帮助企业用好企业微信，赋能员工精细化运营私域，实现360°私域客户营销和管理，智能分配客户，实时追踪不同渠道客户添加效果",
          img: "https://image.ydleague.com/benefit/web_icons/icon_website_about_banner_01.png",
        },
        {
          sign: "food",
          name: "餐饮外卖",
          title: "线下门店经营、线上外卖平台打通",
          desc: "更快速的连接，更高效的增长，以企业的客户运营为中心，整合线上和线下，打通公域及私域，打造「获客·运营·转化·管理」一体化数字营销闭环系统，助力企业私域精细化运营，帮助企业用好企业微信，赋能员工精细化运营私域，实现360°私域客户营销和管理，智能分配客户，实时追踪不同渠道客户添加效果",
          img: "https://image.ydleague.com/benefit/web_icons/icon_website_about_banner_01.png",
        },
      ],
      loginUser: null,
    };
  },
  props: {
    initTheme: {
      type: String,
      default: "dark",
    },
  },
  created() {
    this.theme = this.initTheme;
    if (this.initTheme !== "light") {
      this.windowWidth = window.innerWidth;
      this.$bus.$on("windowScroll", (scrollY) => {
        if (scrollY > 350) this.theme = "light";
        else this.theme = this.initTheme;
        console.log("windowScroll", scrollY);
      });
    }
  },
  mounted() {
    let _stoken = this.$yd.storage.get("_stoken");
    // console.log("_stoken", _stoken);
    if (_stoken) {
      this.loadUser();
    }
  },
  methods: {
    async pageToHome() {
      let _token = this.$yd.storage.get("_stoken");
      // let shopId = _token.scope;
      console.log("_token", _token);
      if (_token.scope) {
        this.$routerPush({
          path: `/plat/$shopId/shop/dashboard`,
        });
      } else {
        this.$routerPush({
          path: `/shops`,
        });
      }
    },
    async loadUser() {
      let res = await getLoginInfo();
      console.log(res);
      this.loginUser = res.data;
    },
    pageToSolution(product) {
      this.$routerPush({
        path: `/products/${product.sign}`,
      });
    },
    setProductCarousel(index) {
      this.$refs.productCarousel.setActiveItem(index);
    },
    openProduct(product) {
      this.$router.push({
        name: product.route,
        // query: { userId: user.id },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-page-head {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  transition: all 0.15s;
  background-color: rgba($color: #000000, $alpha: 0.4);

  &.relative {
    position: relative;
    top: inherit;
    left: inherit;
  }

  &:hover,
  &.light {
    background-color: #ffffff;
    box-shadow: 8px 8px 20px 0 rgba(55, 99, 170, 0.03);

    .head-menu-item {
      color: #2b2b2b;

      .menu-item-text {
        color: #2b2b2b;
      }

      &:hover {
        color: #0366ff;

        .menu-item-text {
          color: #0366ff;
        }
      }
    }

    .head-item .head-logo {
      background-position: 0 0;
    }

    .head-item-btn {
      color: #2b2b2b;

      &:hover {
        color: #0366ff;
      }
    }

    .head-dashboard {
      color: rgba($color: #0366ff, $alpha: 0.8);
      border-color: rgba($color: #0366ff, $alpha: 0.8);

      &:hover {
        color: #0366ff;
        border-color: #0366ff;
        box-shadow: 0 2px 12px rgba($color: #0366ff, $alpha: 0.2);
      }
    }
  }

  &.transparent {
    background-color: transparent;
    border-bottom: 1px solid rgba($color: #e5e5e5, $alpha: 0.7);
    // box-shadow: 8px 8px 20px 0 rgba(55, 99, 170, 0.03);

    &:hover {
      background-color: #ffffff;
    }

    .head-menu-item {
      color: #2b2b2b;

      .menu-item-text {
        color: #2b2b2b;
      }

      &:hover {
        color: #0366ff;

        .menu-item-text {
          color: #0366ff;
        }
      }
    }

    .head-item .head-logo {
      background-position: 0 0;
    }

    .head-item-btn {
      color: #2b2b2b;

      &:hover {
        color: #0366ff;
      }
    }

    .head-dashboard {
      color: rgba($color: #0366ff, $alpha: 0.8);
      border-color: rgba($color: #0366ff, $alpha: 0.8);

      &:hover {
        color: #0366ff;
        border-color: #0366ff;
        box-shadow: 0 2px 12px rgba($color: #0366ff, $alpha: 0.2);
      }
    }
  }

  .head-menu-item {
    height: 70px;
    margin: 0 20px;
    color: rgba(255, 255, 255, 0.9);
    cursor: pointer;

    display: inline-flex;
    align-items: center;
    transition: all 0.15s;
    text-decoration: none;

    &:hover {
      color: #ffffff;

      .menu-item-text {
        color: #ffffff;
      }

      .menu-item-block {
        height: 500px;
        opacity: 1;
      }
    }

    .menu-item-block {
      position: fixed;
      top: 70px;
      left: 0;
      right: 0;
      height: 0;
      width: 100%;
      opacity: 0;
      z-index: -1;
      overflow: hidden;
      transition: all 0.3s;
      // box-shadow: 0 2px 12px rgba($color: #000000, $alpha: 0.05);
      // box-shadow: 8px 8px 20px 0 rgba(55, 99, 170, 0.03);
      box-shadow: 0px 12px 48px 0px rgba(0, 33, 71, 0.07);

      .menu-item-product {
        width: 100%;
        height: 500px;
        overflow: auto;
        box-sizing: border-box;
        border-top: 2px solid #0366ff;
        background-color: #ffffff;
        background-image: linear-gradient(
          to right,
          #ffffff 0%,
          #ffffff 40%,
          #f1f2f5 41%,
          #f1f2f5 100%
        );

        .product-block {
          width: 1440px;
          margin: 0 auto;
          height: 100%;
          display: flex;

          .product-block-left {
            width: 220px;
            height: 100%;
            box-sizing: border-box;
            padding: 32px 20px 20px;
            background-color: #ffffff;
            background-size: 400px auto;
            background-repeat: no-repeat;
            background-position: bottom right;
            background-image: url(https://image.ydleague.com/benefit/web_icons/icon_product_left_bg.jpg);

            .category-title {
              padding: 6px 16px;
              box-sizing: border-box;
              margin-bottom: 12px;
              line-height: 34px;
              font-weight: 600;
              font-size: 14px;
              color: #1e2226;
            }

            .category-item {
              width: 180px;
              margin-top: 6px;
              padding: 6px 16px;
              box-sizing: border-box;
              line-height: 22px;
              font-size: 14px;
              color: #686767;
              cursor: pointer;
              transition: all 0.3s;

              display: flex;
              align-items: center;
              justify-content: space-between;

              &:hover {
                color: #0366ff;
                border-radius: 4px;
                background-color: #f7f8fa;

                .category-arrow {
                  transform: translateX(0);
                  opacity: 0.6;
                }
              }

              .category-arrow {
                transition: all 0.3s;
                transform: translateX(50px);
                font-size: 18px;
                opacity: 0;
              }
            }
          }

          .product-block-right {
            width: 1220px;
            height: 100%;
            background-color: #f1f2f5;

            .product-contents {
              width: 100%;
              height: 100%;
              padding: 20px 50px;
              box-sizing: border-box;

              display: flex;
              align-items: center;
              justify-content: space-between;

              .product-info {
                width: 0;
                flex-grow: 1;

                .product-info-title {
                  line-height: 48px;
                  font-weight: 600;
                  font-size: 28px;
                  color: #1e2226;
                }

                .product-info-text {
                  margin-top: 20px;
                  line-height: 32px;
                  font-size: 16px;
                  color: #686767;
                }

                .product-info-btn {
                  height: 32px;
                  margin-top: 20px;
                  line-height: 32px;
                  display: inline-flex;
                  align-items: center;
                  color: #0366ff;

                  &:hover {
                    .btn-icon {
                      transform: translateX(6px);
                    }
                  }

                  .btn-icon {
                    transition: all 0.3s;
                    margin-left: 4px;
                    font-size: 18px;
                  }
                }
              }

              .product-banner {
                width: 460px;
                height: 340px;
              }
            }
          }
        }
      }
    }

    .menu-item-text {
      letter-spacing: 1px;
      line-height: 32px;
      font-size: 15px;
      text-decoration: none;
      color: rgba(255, 255, 255, 0.9);
    }
  }

  .head-item {
    display: inline-flex;
    align-items: center;

    .head-logo {
      width: 124px;
      height: 40px;
      background-size: 124px 80px;
      background-position: 0 40px;
      // background-image: url(https://image.ydleague.com/benefit/web_icons/icon_saas_adm_logo.png);
      background-image: url(https://image.ydleague.com/benefit/test/test_logo2.png);
    }
  }

  .head-item-btn {
    margin: 0 10px;
    font-size: 14px;
    cursor: pointer;
    transition: all 0.15s;
    text-decoration: none;
    color: rgba(255, 255, 255, 0.9);
  }

  .head-dashboard {
    width: 88px;
    height: 32px;
    margin: 0 10px;
    border-radius: 4px;
    border: 1px solid rgba(255, 255, 255, 0.7);

    text-align: center;
    line-height: 30px;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.9);
    cursor: pointer;
    transition: all 0.15s;
  }

  .page-head-container {
    width: 1280px;
    height: 70px;
    margin: 0 auto;
    position: relative;

    .custom-head {
      width: 100%;
      height: 70px;

      display: flex;
      align-items: center;
      justify-content: center;

      .head-container {
        position: absolute;
        top: 20px;
        left: 40px;
        width: 1200px;
        height: 30px;

        display: flex;
        align-items: center;
        justify-content: space-between;

        .head-menus {
          width: 0;
          flex-grow: 1;
          margin-left: 50px;

          display: inline-flex;
          align-items: center;
        }
      }
    }
  }
}

.product-list {
  width: 260px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;

  padding: 0 12px 12px;
  box-sizing: border-box;

  .product-item {
    width: 48%;
    height: 24px;
    line-height: 24px;
    font-size: 14px;
    transition: all 0.2s;
    cursor: pointer;

    padding: 0 12px;
    margin-top: 12px;
    box-sizing: border-box;
    border-radius: 4px;

    display: inline-flex;
    align-items: center;
    justify-content: space-between;

    &:hover {
      border-radius: 12px;
      background-color: #0366ff;
      color: #ffffff;

      i {
        color: #ffffff;
        border-color: #ffffff;
      }
    }

    i {
      display: inline-flex;
      align-items: center;
      justify-content: center;

      width: 16px;
      height: 16px;
      margin-left: 12px;
      border-radius: 50%;
      border: 1px solid #e5e5e5;
      font-size: 12px;
      color: #8a9099;
      box-sizing: border-box;
    }
  }
}
</style>
