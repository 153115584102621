<template>
  <div class="normal-dialog" :style="{ background: background }">
    <div
      class="normal-dialog-head"
      :class="{
        dark: dark,
        fixed: fixedHead,
      }"
    >
      <div class="head-title">
        <div v-if="title" class="title-text">{{ title }}</div>
        <div v-if="subtitle" class="subtitle-text">{{ subtitle }}</div>
        <slot name="title"></slot>
      </div>
      <div class="head-right">
        <div v-if="showClose" @click="closeDialog" class="head-close"></div>
      </div>
    </div>
    <div class="normal-dialog-body">
      <slot name="body"></slot>
    </div>
    <div v-if="showFoot" class="normal-dialog-foot">
      <div class="foot-left"></div>
      <div class="foot-right">
        <template v-if="!btns || btns.length === 0">
          <el-button
            v-if="showCancel"
            @click="closeDialog"
            type="infor"
            size="small"
            >{{ cancelText }}</el-button
          >
          <el-button
            v-if="showSubmit"
            @click="submitDialog"
            type="primary"
            size="small"
            >{{ submitText }}</el-button
          >
        </template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    subtitle: {
      type: String,
      default: "",
    },
    background: {
      type: String,
      default: "transparent",
    },
    dark: {
      type: Boolean,
      default: false,
    },
    fixedHead: {
      type: Boolean,
      default: false,
    },
    showClose: {
      type: Boolean,
      default: true,
    },
    showCancel: {
      type: Boolean,
      default: true,
    },
    showSubmit: {
      type: Boolean,
      default: true,
    },
    cancelText: {
      type: String,
      default: "取消",
    },
    submitText: {
      type: String,
      default: "确定",
    },
    showFoot: {
      type: Boolean,
      default: true,
    },
    btns: { type: Array },
  },
  created() {
    console.log(this.showFoot);
  },
  methods: {
    submitDialog() {
      this.$emit("submit");
    },
    closeDialog() {
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss" scoped>
.normal-dialog {
  position: relative;

  .normal-dialog-head {
    height: 64px;
    padding: 20px 24px;
    box-sizing: border-box;
    background-color: #ffffff;

    display: flex;
    align-items: center;
    justify-content: space-between;

    &.fixed {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      z-index: 100;

      &.dark {
        background-image: linear-gradient(
          to bottom,
          rgba($color: #000000, $alpha: 0.4),
          rgba($color: #000000, $alpha: 0)
        );
        background-color: rgba($color: #000000, $alpha: 0);
      }
    }

    &.dark {
      background-color: #000000;

      .head-title {
        .title-text {
          color: #ffffff;
        }
      }

      .head-right {
        .head-close {
          width: 16px;
          height: 16px;
          background-size: 16px;
          background-image: url(https://image.ydleague.com/benefit/web_icons/icon_normal_close_white.png);
          cursor: pointer;
          opacity: 0.8;

          &:hover {
            opacity: 1;
            background-position: 0 0;
          }
        }
      }
    }

    .head-title {
      display: inline-flex;
      align-items: center;

      .title-text {
        margin-right: 12px;
        line-height: 24px;
        font-weight: 600;
        font-size: 16px;
        color: #1e2226;
      }

      .subtitle-text {
        margin-right: 12px;
        line-height: 24px;
        font-size: 14px;
        color: #686767;
      }
    }

    .head-right {
      .head-close {
        width: 16px;
        height: 16px;
        background-size: 96px 16px;
        background-image: url(https://image.ydleague.com/benefit/web_icons/icon_normal_close.png);
        cursor: pointer;

        &:hover {
          background-position: -32px;
        }
      }
    }
  }

  .normal-dialog-foot {
    padding: 12px 24px 20px;
    box-sizing: border-box;
    background-color: #ffffff;

    display: flex;
    align-items: center;
    justify-content: space-between;

    .foot-left {
      display: inline-flex;
      align-items: center;
    }

    .foot-right {
      display: inline-flex;
      align-items: center;
    }
  }
}
</style>
